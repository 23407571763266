.paper {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
}

.paper .box-list {
    background-color: #0f74a8;
    padding: 10px 10px 10px 10px;
}